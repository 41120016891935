import React from 'react';
import './assets/style/index.css';
import Hero from './Hero';
import Intro from './Intro';
const Home = () => {
	return (
		<>
			<Hero />
			<Intro />
		</>
	);
};

export default Home;
